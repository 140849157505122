/* Application-wide Styles */
html {
  height: 100%;
  display: flex;
}

body {
  height: 100%;
  width: 100%;
  margin: 0em;
  color: #333;
  font-family: Cambria, Georgia;
  /*   .mat-mdc-tooltip{
      --mdc-plain-tooltip-supporting-text-size: medium;
    } */
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-children {
  display: flex;
  justify-content: center;
}

.fill-space {
  /* This fills the remaining space, by using flexbox. */
  /* Every toolbar row uses a flexbox row layout. */
  flex: 1 1 auto;
}

.full-space {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

router-outlet + * {
  position: relative;
  min-height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  z-index: 0;
}

h1 {
  color: #369;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 250%;
}

h2, h3 {
  color: #444;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
}

.text {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: x-large;
  white-space: nowrap;
}

a.selected {
  background-color: rgba(0, 0, 0, 0.1333333333);
}

.alert {
  margin: 1em;
}

.scomp {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  height: 100%;
  overflow-y: auto;
}

.corner-top-right {
  position: absolute;
  width: 0;
  height: 0;
  top: 0;
  right: 0;
  border-style: solid;
  border-width: 0 20px 20px 0;
  border-color: transparent #007bff transparent transparent;
}

.question {
  text-align: center;
  margin: 20px 40px 20px 40px;
  font-size: x-large;
  white-space: wrap;
}

.instruction {
  text-align: center;
  font-size: large;
  margin-bottom: 5px;
  margin-top: 20px;
  padding-left: 10%;
  padding-right: 10%;
}
.instruction .mat-icon {
  vertical-align: middle;
}

.qinput {
  display: block;
  width: 50px;
  font-size: large;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  text-align: center;
}

.finput {
  display: block;
  resize: none;
  width: 200px;
  min-height: 150px;
  font-size: large;
  padding: 8px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  word-wrap: normal;
}

/* Container*/
.sidenav-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.main-container {
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
}

.container {
  flex: 1 1 auto;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.container-confirm {
  width: 365px !important;
}

/* Content */
.content {
  position: relative;
  flex: 1 1 auto;
  display: flex;
  min-height: 0;
  min-width: 0;
  max-width: 100%;
  flex-direction: row;
  justify-content: center;
}

.content-component {
  margin: auto;
  padding: 40px;
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  overflow: auto;
}

/* Feedback */
.feedback-container, .feedback-container-hidden {
  margin: auto 0 auto 0;
  position: relative;
  height: auto;
}

.feedback-container-hidden {
  flex: 0 0 auto;
  min-width: 0;
  visibility: hidden;
}

/* Legend */
/* Dialog */
.duplicate-uuid-dialog {
  width: 585px !important;
}

/* Baustein */
.baustein {
  margin: 2px 5px 0px 5px;
}

.baustein-soft {
  opacity: 0.5;
}

.baustein-off {
  opacity: 0.1;
}

/* Block */
.block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  min-width: 200px;
  padding-top: 10px;
}

/* Disable */
.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.disabled-strong {
  pointer-events: none;
  opacity: 0.1;
}

/* Material */
.legend {
  font-size: medium;
  background-color: rgb(100, 100, 100);
  padding: 6px;
}

.service {
  font-size: medium;
  background-color: rgb(100, 100, 100);
  padding: 6px;
  max-width: 300px !important;
}

.service-menu {
  font-size: medium;
  background-color: rgb(100, 100, 100);
}

.baustein-title {
  font-size: medium;
  background-color: rgb(100, 100, 100);
  padding: 6px;
}

.stepper-tooltip {
  font-size: small;
  background-color: rgb(100, 100, 100);
  padding: 2px;
}

.checklist-tooltip {
  font-size: small;
  background-color: rgb(100, 100, 100);
  padding: 2px;
  white-space: pre-line;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons Round", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

.mat-mdc-form-field {
  width: 100%;
}

/* Title */
.title-duplicate-uuid-alert {
  font-size: 15px !important;
  text-align: justify;
}

/* Button */
button {
  color: #333;
  letter-spacing: 0px !important;
  font-family: Cambria, Georgia;
}

.mat-button, .mat-icon-button, .button-new-site, .mat-stroked-button, .mat-flat-button {
  box-sizing: border-box;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  text-align: center;
  margin: 0;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;
  border-radius: 4px;
  overflow: visible;
}

.button-bordered, .button-bordered-selection {
  margin: 1em !important;
  border: 0.5px solid !important;
  border-color: primary;
  border-radius: 3px !important;
  padding: 0em 2em 0em 2em !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.mat-mdc-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.mat-mdc-raised-button, .mat-mdc-stroked-button, .mat-mdc-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.button-confirm {
  background-color: #284E74 !important;
  color: white !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.button-cancel {
  color: #284E74 !important;
  background-color: white !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.button-bordered-selection {
  width: 300px;
  color: #284E74 !important;
  border-color: #284E74 !important;
  font-weight: bold;
  background-color: #ffffff;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.button-bordered-selection.checked {
  border: 1px solid !important;
  color: #ffffff !important;
  border-color: #284E74 !important;
  background-color: #6F8EAD !important;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.button-bordered-selection:hover {
  color: #284E74 !important;
  border-color: #284E74 !important;
  background-color: #C3D3E3 !important;
}

.stepper-buttons-container-left {
  margin-right: auto;
  margin-left: 50px;
  margin-top: -10px;
}

.stepper-buttons-container-right {
  margin-left: auto;
  margin-right: 50px;
  margin-top: -10px;
}

.button-prev {
  color: black;
  font-size: larger;
  order: 1;
  margin-left: 20px;
}

.button-next {
  order: 2;
  margin-right: 20px;
  color: black;
  font-size: larger;
}

.button-continue {
  color: black;
  font-size: large;
}

.button-bordered-small {
  border: 0.5px solid !important;
  border-color: primary;
  border-radius: 3px !important;
  margin-left: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #fff;
}

.input-button-selection {
  width: 300px;
  height: 10px;
  margin: 1em;
  padding: 1em 0em 1em 0em;
  text-align: center;
  border: 1px solid #284E74;
  border-radius: 3px !important;
  font-weight: bold;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.input-button-selection::placeholder {
  font-weight: normal;
  color: #284E74;
}

.input-button-selection.checked {
  color: #ffffff;
  border: 1px solid #284E74;
  background-color: #6F8EAD;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.input-button-selection.checked::placeholder {
  color: #ffffff;
}

.button-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  color: #007bff;
  text-decoration: underline;
  cursor: pointer;
}

.material-icons {
  font-size: 24px;
  width: 40px;
  font-family: "Material Icons Round", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Roboto";
}

.button-new-site {
  height: unset !important;
  line-height: unset !important;
  margin: 1em !important;
  border: 2px solid #333 !important;
  background-color: transparent !important;
  border-radius: 0em !important;
  padding: 1em !important;
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

/* Slie-Toggle only necessary as long as legacy code is used in app.module.ts */
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #ffab40;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(255, 171, 64, 0.54);
}

.mat-slide-toggle.mat-checked .mat-ripple-element {
  background-color: black;
}

.mat-slide-toggle:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.mat-slide-toggle:not(.mat-checked):not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: grey;
}

.mat-slide-toggle:not(.mat-checked):not(.mat-disabled) .mat-ripple-element {
  background-color: black;
}

/* everywhere else */
* {
  font-family: Arial, Helvetica, sans-serif;
}